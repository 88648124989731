import isNode                                     from 'detect-node';
import React                                      from 'react';
import PropTypes                                  from 'prop-types';
import jQuery                                     from 'jquery';
import { SectionModule, SubmenuModule }           from '../../api/com/ewing/social/component';
import { TypeEnum }                               from '../../api/com/ewing/social/enum';
import { IPageProps, ISectionState, ISiteModule } from '../../api/com/ewing/social/interface';
import { Util }                                   from '../../api/com/ewing/social/tool';
import { SiteModule }                             from '../../api/com/ewing/social/module/api/site.module';
import { URLManager }                             from '../../api/com/ewing/social/manager/url.manager';
import { BaseSection }                            from './BaseSection';
import { Section }                                from '../organism';
import { Submenu }                                from '../organism/Submenu';
import { Page }                                   from '../Page';
import { Main }                                   from './Main';

/**
 * @class BasePage
 * @extends BaseSection
 * @abstract
 * @author Isaac Ewing
 * @version 1.0.0 12/18/20 01:09 pm
 */
export abstract class BasePage<P = Partial<IPageProps>, S = Partial<ISectionState>> extends BaseSection<Partial<IPageProps>> {
    /**
     *
     * @type {string}
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/18/20 01:09 pm
     */
    protected static COMPONENT_CLASS: string           = 'page-base';
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 05/08/21 12:20 pm
     */
    protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } BASE ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
    /**
     *
     * @type {string}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/18/20 01:09 pm
     */
    protected static readonly SUBMENU_ID: string       = 'sticky-submenu';
    /**
     *
     * @type {any}
     * @readonly
     * @static
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 12/18/20 01:09 pm
     */
    protected static readonly CONSOLE_LOG              = {
        MODULE: {
            ALL     : false,
            SITE    : true,
            DOMAIN  : true,
            PAGE    : true,
            PAGES   : true,
            SETUP   : true,
            SECTION : true,
            SECTIONS: true,
        },
    };
    /**
     *
     * @type {any}
     * @static
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/18/20 01:09 pm
     */
    public static propTypes                            = {
        ...BaseSection.propTypes,
        module: PropTypes.instanceOf( SectionModule ),
        page  : PropTypes.instanceOf( SiteModule ),
    };
    /**
     *
     * @type {JSX.Element[]}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 05/01/21 05:05 pm
     */
    protected sections: JSX.Element[]                  = null;
    /**
     *
     * @type {SubmenuModule}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 05/02/21 01:07 pm
     */
    protected submenu: SubmenuModule                   = null;
    /**
     *
     * @type {boolean}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 05/03/21 06:40 pm
     */
    protected submenuRegistered: boolean               = null;
    /**
     *
     * @type {Partial<IPageProps>}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 05/29/21 02:29 pm
     */
    public readonly props: Partial<IPageProps>         = null;
    /**
     *
     * @type {unknown}
     * @readonly
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 06/05/21 07:20 pm
     */
    public state: Partial<ISectionState | ISiteModule> = null;

    /**
     *
     * @param data {SiteModule}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 03/24/21 11:19 am
     */
    protected onPageObserver( data: SiteModule | unknown ): void {
        console.log( `${ BasePage.CONSOLE_PREFIX } RUNNING onPageObserver method...`, { node: isNode } );

        const module: SiteModule = data as SiteModule;

        if( !isNode ) {
            const domain: string   = URLManager.Domain;
            const url: string      = window.location.href;
            const heading: string  = module.page.text !== 'home' ? ` - ${ module.page.text }` : '';
            const title: string    = Util.capitalizeFirstLetter( `${ process.env.REACT_APP_NAME }${ heading }`, true );
            const description      = `${ process.env.REACT_APP_NAME } - ${ process.env.REACT_APP_SLOGAN } - ${ process.env.REACT_APP_DESCRIPTION }`;
            const keywords: string = `${ process.env.REACT_APP_NAME }, ${ process.env.REACT_APP_KEYWORDS }`.toLowerCase();
            const copyright        = `Copyright, ${ title } ${ new Date().getFullYear() }`;

            document.title = title;
            document.querySelector( 'meta[name="description"]' )?.setAttribute( 'content', description );
            document.querySelector( 'meta[name="keywords"]' )?.setAttribute( 'content', keywords );
            document.querySelector( 'meta[name="copyright"]' )?.setAttribute( 'content', copyright );
            document.querySelector( 'meta[name="domain"]' )?.setAttribute( 'content', domain );
            document.querySelector( 'meta[property="og:title"]' )?.setAttribute( 'content', title );
            document.querySelector( 'meta[property="og:url"]' )?.setAttribute( 'content', url );
            document.querySelector( 'meta[property="og:site_name"]' )?.setAttribute( 'content', title );
            document.getElementById( 'metaLinkCanonical' )?.setAttribute( 'href', url );
        }

        this.registerSiteModule( module );
    }

    /**
     *
     * @param {SectionModule} module
     * @param {SiteModule} page
     * @return {JSX.Element}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 03/24/21 11:27 am - documented
     */
    protected registerSectionModule( module: SectionModule, page: SiteModule ): JSX.Element {
        const hash: string = Util.registerHash();

        if( BasePage.CONSOLE_LOG.MODULE.ALL || BasePage.CONSOLE_LOG.MODULE.SECTION ) {
            console.log( `${ BasePage.CONSOLE_PREFIX } --- section`, { module, hash }, '--- submenu', !!this.submenu );
        }

        try {
            if( this.submenu ) {
                if( module.type === TypeEnum.Hero ) {
                    const submenu: JSX.Element = this.registerSubmenu( hash );

                    console.log( `${ BasePage.CONSOLE_PREFIX } --- submenu jsx`, submenu );
                    return (
                        <React.Fragment key={ `${ hash }-fragment` }>
                            <Section key={ `${ hash }-section` } module={ module } page={ page } />
                            { submenu }
                        </React.Fragment>
                    );
                } else if( module.curve ) {
                    module.enableSubmenu();
                }
            }

            return <Section key={ `${ hash }-section` } module={ module } page={ page } />;
        } catch( exception: unknown ) {
            if( exception instanceof Error ) {
                return <div key={ `${ hash }-div` }>ERROR: { exception.message }</div>;
            }

            return <div key={ `${ hash }-div` }>ERROR: { `${ exception }` }</div>;
        }
    }

    /**
     *
     * @param {Set<SectionModule>} modules
     * @param {SiteModule} page
     * @return {JSX.Element[]}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 03/24/21 11:27 am - documented
     */
    protected registerSectionModules( modules: Set<SectionModule>, page: SiteModule ): JSX.Element[] {
        if( BasePage.CONSOLE_LOG.MODULE.ALL || BasePage.CONSOLE_LOG.MODULE.SECTIONS ) {
            console.log( `${ BasePage.CONSOLE_PREFIX } --- sections`, modules );
            console.log( `${ BasePage.CONSOLE_PREFIX } PAGE SUBMENU...`, !!this.submenu );
        }

        try {
            const sections: JSX.Element[] = [];

            modules.forEach( ( module: SectionModule ): number => sections.push( this.registerSectionModule( module, page ) ) );

            return sections;
        } catch( exception: unknown ) {
            if( exception instanceof Error ) {
                return [ <div key={ Util.registerHash() }>ERROR: { exception.message }</div> ];
            }

            return [ <div key={ Util.registerHash() }>ERROR: { `${ exception }` }</div> ];
        }
    }

    /**
     *
     * @param {SiteModule} module
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 03/24/21 11:27 am - documented
     */
    protected registerSiteModule( module: SiteModule ): void {
        if( BasePage.CONSOLE_LOG.MODULE.ALL || BasePage.CONSOLE_LOG.MODULE.SITE ) {
            console.log( `${ BasePage.CONSOLE_PREFIX } --- site`, module );
        }

        console.log( `${ BasePage.CONSOLE_PREFIX } BASE PAGE is about to set the sections property...`,
                     { sections: this.sections, submenu: !!this.submenu, registered: this.submenuRegistered } );
        //this.sections = this.registerSectionModules( module.sections, module );
        this.setState( {
                           module,
                           page: module,
                       } );

        if( !!this.submenu && !this.submenuRegistered ) {
            console.log( `${ BasePage.CONSOLE_PREFIX } --- submenu`, 'running register submenu listener' );
            this.submenuRegistered = true;
            this.registerSubmenuListeners();
        }
    }

    /**
     *
     * @param {string} hash
     * @return {JSX.Element}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 03/24/21 11:27 am - documented
     * @version 1.0.1 05/02/21 01:12 pm - added support for detecting page and module creation
     */
    protected registerSubmenu( hash?: string ): JSX.Element {
        return <Submenu key={ `${ hash ?? Util.registerHash() }-submenu` } module={ SubmenuModule.BuildForDetect( this.module.page.path ) } />;
    }

    /**
     *
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 03/24/21 11:27 am - documented
     */
    protected registerSubmenuListeners(): void {
        if( !isNode ) {
            let prev                     = 0;
            let triggers                 = null;
            let nav: JQuery<HTMLElement> = jQuery( `.${ BasePage.SUBMENU_ID }` );
            const win: JQuery<Window>    = jQuery( window );

            const toggleNav = (): void => {
                const scrollTop: number = win.scrollTop();

                if( scrollTop <= 0 ) {
                    nav.addClass( 'is-anchored' ).removeClass( [ 'is-stuck', 'is-at-top', 'pushed' ] );
                } else {
                    nav.addClass( [ 'is-stuck', 'is-at-top' ] );
                }

                if( !nav.length ) {
                    nav = jQuery( `.${ BasePage.SUBMENU_ID }` );
                }
                if( !triggers ) {
                    triggers = nav?.offset();
                }
                if( triggers ) {
                    if( scrollTop < triggers.top ) {
                        nav.addClass( 'is-anchored' ).removeClass( [ 'is-stuck', 'is-at-top', 'pushed' ] );
                    }

                    nav.toggleClass( 'fixed', scrollTop > triggers.top );
                }
            };

            toggleNav();

            win.on( 'scroll', () => {
                const scrollTop: number = win.scrollTop();

                nav.toggleClass( 'pushed', scrollTop < prev );
                toggleNav();
                prev = scrollTop;
            } );
        } else {
            console.log( `${ BasePage.CONSOLE_PREFIX } @@@ the window error was in app component...` );
        }
    }

    /**
     *
     * @param props {Partial<IPageProps>}
     * @protected
     * @author Isaac Ewing
     * @version 1.0.0 03/24/21 11:27 am - documented
     */
    protected constructor( props: Partial<IPageProps> ) {
        super( props );

        //this.onQueryPageError      = this.onQueryPageError.bind( this );
        //this.onQueryPageCompleted  = this.onQueryPageCompleted.bind( this );
        this.onPageObserver        = this.onPageObserver.bind( this );
        //this.registerQueryPage     = this.registerQueryPage.bind( this );
        this.registerSiteModule    = this.registerSiteModule.bind( this );
        this.registerSectionModule = this.registerSectionModule.bind( this );

        console.log( `${ BasePage.CONSOLE_PREFIX } BASE PAGE constructed in the base page constructor...` );
    }

    /**
     *
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/18/20 01:09 pm
     */
    public componentDidMount(): void {
        console.log( `${ BasePage.CONSOLE_PREFIX } BASE PAGE has mounted, calling query page...`, { submenu: !!this.submenu, registered: this.submenuRegistered } );

        if( !!this.submenu && !this.submenuRegistered ) {
            console.log( `${ BasePage.CONSOLE_PREFIX } --- submenu`, 'running register submenu listener' );
            this.submenuRegistered = true;
            this.registerSubmenuListeners();
        }
    }

    /**
     *
     * @return {void}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/18/20 01:09 pm
     */
    public componentWillUnmount(): void {
        this.module = null;
        jQuery( window ).off( 'scroll' );

        console.log( `${ BasePage.CONSOLE_PREFIX } BASE PAGE`, 'just finished calling UNMOUNT...' );
    }

    /**
     *
     * @return {JSX.Element}
     * @public
     * @author Isaac Ewing
     * @version 1.0.0 12/18/20 01:09 pm
     */
    public render(): JSX.Element {
        console.info( `${ BasePage.CONSOLE_PREFIX } BASE PAGE DATA`, { props: this.props, data: this.props.data, context: this.props.pageContext } );
        console.log( `${ BasePage.CONSOLE_PREFIX } +++ BASE PAGE MODULE [ ${ this.props?.page?.text } ]`, this.module );

        return (
            <Page module={ this.module } className={ BasePage.componentClass }>
                <Main module={ this.module } className={ [ ...this.state.className ].join( ' ' ) }>{ this.sections }</Main>
            </Page>
        );
    }
}