import React            from 'react';
import { graphql }     from 'gatsby';
import { SiteModule }  from '../api/com/ewing/social/module/api/site.module';
import { QueryModule } from '../api/com/ewing/social/module/graphql/query.module';
import { PageObserver } from '../api/com/ewing/social/observer/page.observer';
import { BasePage }     from '../components/atom/BasePage';

/**
 * @class Home
 * @extends BasePage
 * @author Isaac Ewing
 * @version 1.0.0 01/24/21 09:51 pm - documented
 */
export class Home extends BasePage {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 09:51 pm - documented
      */
     protected static readonly COMPONENT_CLASS: string = 'page-home';

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 01/24/21 09:51 pm - documented
      */
     public constructor( props: any ) {
          super( props );

          console.info( 'INDEX PAGE DATA', { props, data: props.data, context: this.context } );

          if( props?.data ) {
               this.module = SiteModule.Build( props.data );
          }

          this.state = {
               module   : ( this.module ?? null ) as any,
               page     : this.module ?? null,
               path     : props?.path ?? null,
               hash     : this.registerHash(),
               loader   : this.registerHash(),
               query    : null,
               data     : null,
               submenu  : null,
               className: new Set<string>( [ 'root-main', Home.COMPONENT_CLASS ] ),
               children : new Set<JSX.Element>(),
          };

          BasePage.COMPONENT_CLASS = Home.COMPONENT_CLASS;
          PageObserver.subscribe( Home.COMPONENT_CLASS, this.onPageObserver );
     }

     /**
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 03/26/21 09:03 pm
      *
      */
     public componentWillUnmount(): void {
          super.componentWillUnmount();
          PageObserver.unsubscribe( Home.COMPONENT_CLASS );
     }
}
/*
export const query: void = graphql`
     query page( $domainName: String ) {
          socialAPI {
               getSitePage(
                    where: { domainName: $domainName, pageName: "" }
                    options: { includes: { contact: true, invite: true } }
               ) {
                    domain {
                         id
                         path
                         text
                    }
                    page {
                         id
                         path
                         text
                    }
                    setup {
                         id
                         social
                         claim
                         login
                         intro
                    }
                    invite {
                         id
                         active
                         nonce
                         used
                         code
                    }
                    sections {
                         id
                         type
                         style
                         order
                         curve
                         layout {
                              id
                              title {
                                   id
                                   text
                              }
                              description {
                                   id
                                   text
                              }
                         }
                         data {
                              id
                              text
                         }
                         title {
                              id
                              text
                         }
                         subtitle {
                              id
                              text
                         }
                         description {
                              id
                              text
                         }
                         menu {
                              id
                              submenu
                              discord
                              facebook
                              instagram
                              onlyfans
                              patreon
                              tiktok
                              twitch
                              twitter
                              youtube
                              columns
                              links {
                                   id
                                   path
                                   text
                                   icon
                              }
                         }
                         gallery {
                              id
                              isGallery
                              valign
                              halign
                              images {
                                   id
                                   image {
                                        id
                                        domain {
                                             id
                                        }
                                        source
                                        alt
                                        favor
                                        valign
                                        halign
                                        rounded
                                        greyed
                                        shadow
                                        webp
                                        png
                                        jpg
                                        jpeg
                                        gif
                                        svg
                                        jxr
                                   }
                                   title {
                                        id
                                        text
                                   }
                                   link {
                                        id
                                        path
                                        text
                                        icon
                                   }
                              }
                         }
                         image {
                              id
                              domain {
                                   id
                              }
                              source
                              alt
                              favor
                              valign
                              halign
                              rounded
                              greyed
                              shadow
                              webp
                              png
                              jpg
                              jpeg
                              gif
                              svg
                              jxr
                         }
                         button {
                              id
                              path
                              text
                              icon
                         }
                    }
               }
          }
     }
`;

*/
export default Home;