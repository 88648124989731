import React          from 'react';
import PropTypes      from 'prop-types';
import { SiteModule } from '../../api/com/ewing/social/module';

/**
 *
 * @param {SiteModule} module
 * @param {string} className
 * @param {JSX.Element[]} children
 * @return {JSX.Element}
 * @constructor
 * @author Isaac Ewing
 * @version 1.0.0 05/01/21 04:31 pm
 */
export function Main( { module, className, children } ):JSX.Element {
     console.log( '[[ MAIN ]] MAIN COMPONENT', { module, className, children } );

     return (
          <main className={ className }>{ children }</main>
     );
}

Main.propTypes = {
     module   : PropTypes.instanceOf( SiteModule ).isRequired,
     className: PropTypes.string.isRequired,
     children: PropTypes.node.isRequired,
};

export default Main;